import useTranslation from 'next-translate/useTranslation'
import { StyledButton } from './NavButton'

interface ResumeCalculationButton {
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const ResumeCalculationButton = ({
  disabled,
  onClick,
}: ResumeCalculationButton) => {
  const { t } = useTranslation('common')
  return (
    <StyledButton
      size='large'
      action='primary'
      disabled={disabled}
      onClick={onClick}
    >
      {t('buttons.resume')}
    </StyledButton>
  )
}
