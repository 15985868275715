
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { Container, Text } from '@/Introduction/Introduction'
import { NewCalculationButton } from '@/Buttons/NewCalculationButton'
import { useStore } from '@stores/index'
import { styled } from '@styles/config'
import type { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'
import { ResumeCalculationButton } from '@/Buttons/ResumeCalculationButton'

const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const Home: NextPage = observer(() => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { formStore, authStore, costStore } = useStore()

  const newInflowCalculation = () => {
    formStore.createInflowCalculationForm()
    router.push('/inflow-calculation/organisation-details/name')
  }

  const resumeInflowCalculation = () => {
    formStore.setIsNewForm(false)
    router.push(formStore.progress !== 100 ?
      (formStore.currentUrl || '/inflow-calculation/organisation-details/name') :
      (costStore.currentUrl || '/costs-calculation/basic-information/internship-duration')
    )
  }

  const resumeCalculationAction = () => {
    if (authStore.hasToken) {
      return (formStore.hasLocalForm ? formStore.setMoveCalculationModalIsOpen : resumeInflowCalculation)
    } else {
      return formStore.setResumeCalculationModalGuestIsOpen
    }
  }

  const newCalculationAction = () => {
    if (formStore.hasLocalForm && authStore.hasToken) {
      return formStore.setMoveCalculationModalIsOpen
    } else if (formStore.hasForm) {
      return formStore.setNewCalculationModalIsOpen
    } else if (authStore.hasToken) {
      return newInflowCalculation
    } else {
      return formStore.setNewCalculationModalGuestIsOpen
    }
  }

  return (
    <Container>
      <Text>
        <Trans i18nKey='common:introduction-instrument-1' components={{ br: <br /> }} />
      </Text>
      <ButtonContainer>
        <ResumeCalculationButton
          disabled={!formStore.hasForm}
          onClick={resumeCalculationAction()}
        />
        <NewCalculationButton onClick={newCalculationAction()} />
      </ButtonContainer>
    </Container>
  )
})

const __Page_Next_Translate__ = Home


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  