import { styled } from '@styles/config'

export const Container = styled('div', {
  width: '773px',
  height: '784px',
  padding: '41px',
  backgroundColor: '$white',
  borderRadius: '$radius',
  boxShadow: '$card',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const Text = styled('div', {
  textAlign: 'center',
  marginBottom: '25px',
  overflow: 'auto',
  maxHeight: '85%',
})

export const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})
